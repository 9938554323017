import * as React from "react"

import { Header } from "./header/Header";
import { Description } from "./description/Description";
import { Games } from "./games/Games";
import { Footer } from "./footer/Footer";

import "./Main.css";

export class Main extends React.Component {
    public componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    public render() {
        return (
            <div className={"container"}>
                <Header />
                <div>
                    <Description />
                    <Games />
                </div>
                <Footer />
            </div>
        );
    };

    private handleResize = () => {
        const windowWidth = window.innerWidth;
        const baseFontSize = windowWidth * 12 / 505;

        document.documentElement.style.fontSize = `${windowWidth < 505 ? baseFontSize : 12}px`;
    }
};
