import * as React from 'react';

import "./Description.css";

export class Description extends React.Component {
    render() {
        return (
            <div className="descriptionContainer">
                <div className="descText">
                    <div>
                        Guruu are passioned iOS and Android mobile game developers.
                        <br />
                        We love what we do and we love to bring a lot of fun to our players.
                    </div>
                </div>
                <div className="tempText">
                    Contact us: <a href="mailto:games@guruu.io">games@guruu.io</a>
                </div>
            </div>
        );
    };
};
