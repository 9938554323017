import * as React from "react";
import { isEqual } from "lodash-es";

import { GameInfo } from "./gamesList";

interface GameProps {
    info: GameInfo;
    index: number;
}

interface GameState {
    shouldPlayGif: boolean;
    sideSpace: number;
    gameWrapperHeight: number;
    shouldShowImage: boolean;
}

function calculateSideSpace() {
    return Math.max((window.innerWidth - 1072) / 2, 50);
}

export class Game extends React.PureComponent<GameProps, GameState> {
    private wrapperElement: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: GameProps) {
        super(props);
        this.state = {
            shouldPlayGif: false,
            sideSpace: calculateSideSpace(),
            gameWrapperHeight: 480,
            shouldShowImage: true,
        };
    }

    public componentDidMount() {
        this.handleScroll();
        this.handleResize();
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);
    }

    public componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleResize);
    }

    public render() {
        const {
            info,
            index,
        } = this.props;
        const {
            iconUrl,
            imageUrl,
            imageUrlTwo,
            gifUrl,
            title,
            description,
            appStoreLink,
            playStoreLink,
        } = info;

        const icon = require(`./assets/${iconUrl}`);
        const image = require(`./assets/${imageUrl}`);
        const image2 = require(`./assets/${imageUrlTwo}`);
        const gif = require(`./assets/${gifUrl}`);

        const isOdd = index % 2 === 0;

        const backgroundStyle: React.CSSProperties = isOdd
            ? { left: `${window.innerWidth > 550 ? this.state.sideSpace / 12 : 0.5}rem` }
            : { right: `${window.innerWidth > 550 ? this.state.sideSpace / 12 : 0.5}rem` };

        const backgroundTriangleStyle: React.CSSProperties = {
            borderWidth: `${(this.state.gameWrapperHeight + 10) / 10}rem 20rem`,
        };

        return (
            <div className={"gameWrapper"} ref={this.wrapperElement}>
                <div className={"background"} style={backgroundStyle}>
                    <div className={"backgroundTriangle"} style={backgroundTriangleStyle}/>
                </div>
                <div className={"gameContent"}>
                    <div className={"media"}>
                        {this.state.shouldShowImage && <img alt="image" src={image2} />}
                        <img alt="gif" src={this.state.shouldPlayGif ? gif : image} />
                    </div>
                    <div className={"info"}>
                        <div className={"icon-img"} >
                          <img alt="image" src={icon} />
                        </div>
                        <div className={"title"}>{title}</div>
                        <div className={"description"}>{description}</div>
                        <div className={"storeButtons"}>
                            <a href={appStoreLink} target={"_blank"} className={appStoreLink == "" ? "hidden" : ""}>
                                <img
                                    alt=""
                                    src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-10-11&kind=iossoftware&bubble=ios_apps"
                                    style={{ width: "88%", margin: "6%" }}
                                />
                            </a>
                            <a href={`${playStoreLink}&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`} target={"_blank"} className={playStoreLink == "" ? "hidden" : ""}>
                                <img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleScroll = () => {
        const wrapperElement = this.wrapperElement.current;

        if (wrapperElement) {
            const {
                top,
                bottom,
            } = wrapperElement.getBoundingClientRect();
            const halfScreenHeight = window.innerHeight / 2;
            const isInMiddle = top <= halfScreenHeight + 50 && bottom > halfScreenHeight - 50;

            if (isInMiddle !== this.state.shouldPlayGif) {
                this.setState({
                    shouldPlayGif: isInMiddle,
                });
            }
        }
    }

    private handleResize = () => {
        const wrapperElement = this.wrapperElement.current;
        const newState: GameState = {
            ...this.state,
            sideSpace: calculateSideSpace(),
            shouldShowImage: true,
        };

        if (wrapperElement) {
            newState.gameWrapperHeight = wrapperElement.getBoundingClientRect().height;
        }

        const windowWidth = window.innerWidth;

        if (windowWidth < 563 || (windowWidth > 768 && windowWidth < 1000)) {
            newState.shouldShowImage = false;
        }

        if (!isEqual(newState, this.state)) {
            this.setState(newState);
        }
    }
}
