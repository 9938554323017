export interface GameInfo {
	title: string;
	iconUrl: string;
	imageUrl: string;
	imageUrlTwo: string;
	gifUrl: string;
	description: string;
	playStoreLink: string;
	appStoreLink: string;
	videoLink?: string;
}

export const gamesList: GameInfo[] = [
{
	title: "Linkmo",
	iconUrl: "linkmo/icon.png",
	imageUrl: "linkmo/1.png",
	imageUrlTwo: "linkmo/2.png",
	gifUrl: "linkmo/linkmo.gif",
	description: "Linkmo is a challenging puzzle game that will keep you entertained for hours!\nGet the color ball to the same color ring. Arrange correctly all colors to pass the level.\nA brain-teasing game that is both tough and relaxing!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/linkmo/id6443485480",
	videoLink: "",
},
{
	title: "Knife Drop",
	iconUrl: "knifeDrop/icon.png",
	imageUrl: "knifeDrop/1.png",
	imageUrlTwo: "knifeDrop/2.png",
	gifUrl: "knifeDrop/knife_drop.gif",
	description: "In Knife Drop you have to throw blades and other sharp object to targets until they break.\nBe precise, reach new high scores and unlock new sharp weapons!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/knife-drop/id1637662078",
	videoLink: "",
},
{
	title: "Shapes Fit",
	iconUrl: "shapesFit/icon.png",
	imageUrl: "shapesFit/1.png",
	imageUrlTwo: "shapesFit/2.png",
	gifUrl: "shapesFit/shapes_fit.gif",
	description: "Shapes Fit is a hyper casual game where you put shapes in the correct place and build stacks.\nReaching 3 layers in stack you crush it and score bonus points.",
	playStoreLink: "https://play.google.com/store/apps/details?id=io.guruu.games.shapes",
	appStoreLink: "https://apps.apple.com/ee/app/shapes-fit/id1608192527",
	videoLink: "",
},
{
	title: "Glass Balls!",
	iconUrl: "glassBalls/icon.png",
	imageUrl: "glassBalls/1.png",
	imageUrlTwo: "glassBalls/2.png",
	gifUrl: "glassBalls/glass_balls.gif",
	description: "Glass Balls is a hyper casual game where you capture balls to complete level.\nDon't collect dark balls otherwise you will loose!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/glass-balls/id1592089757",
	videoLink: "",
},
{
	title: "Upway!",
	iconUrl: "upWay/icon.png",
	imageUrl: "upWay/1.png",
	imageUrlTwo: "upWay/2.png",
	gifUrl: "upWay/up_way.gif",
	description: "Upway! is a hyper casual game where you tap and hold on screen to fly up.\nExperience a new flying mechanics by multiplying blocks under the bird and ascending.",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/upway/id1568264847",
	videoLink: "",
},
{
	title: "Ball Crunch",
	iconUrl: "ballCrunch/icon.png",
	imageUrl: "ballCrunch/1.png",
	imageUrlTwo: "ballCrunch/2.png",
	gifUrl: "ballCrunch/ball_crunch.gif",
	description: "Ball Crunch is a hyper casual game where you trigger chain reactions by throwing ball.\nEasy to play, hard to master.",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/ball-crunch/id1623534909",
	videoLink: "",
},
{
	title: "Love Maze",
	iconUrl: "loveMaze/icon.png",
	imageUrl: "loveMaze/1.png",
	imageUrlTwo: "loveMaze/2.png",
	gifUrl: "loveMaze/love_maze.gif",
	description: "Love Maze is a hyper casual game where you run in to the maze, collect planks to build a bridge and\nreach your couple. Watch out for the enemies and avoid them.",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/love-maze-reach-your-couple/id1617804882",
	videoLink: "",
},{
	title: "Crowd Rush",
	iconUrl: "crowdRush/icon.png",
	imageUrl: "crowdRush/1.png",
	imageUrlTwo: "crowdRush/2.png",
	gifUrl: "crowdRush/crowd_rush.gif",
	description: "Crowd Rush is a hyper casual game where you bounce with the ball and smash the crowds.\nCrowds can run, but they can't hide.",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/crowd-rush-bounce-and-smash/id1601879075",
	videoLink: "",
},{
	title: "Head Fit",
	iconUrl: "headFit/icon.png",
	imageUrl: "headFit/1.png",
	imageUrlTwo: "headFit/2.png",
	gifUrl: "headFit/head_fit.gif",
	description: "Head Fit is a hyper casual game where you tap on head to place a facial part.\nCollect all parts and score points.",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/head-fit/id1616499712",
	videoLink: "",
},{
	title: "Party Pooper!",
	iconUrl: "partyPooper/icon.png",
	imageUrl: "partyPooper/1.png",
	imageUrlTwo: "partyPooper/2.png",
	gifUrl: "partyPooper/party_pooper.gif",
	description: "Party Pooper is a hyper casual game where you push and punch the crowd to ruin the party.\nOne-tap easy-to-learn controls.",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/party-pooper/id1580021086",
	videoLink: "",
},
{
	title: "Dice Duel",
	iconUrl: "dice_duel/icon.png",
	imageUrl: "dice_duel/1.png",
	imageUrlTwo: "dice_duel/2.png",
	gifUrl: "dice_duel/dice_duel.gif",
	description: "Dice Duel is a hyper casual game where you throw the dice to get units and destroy enemy tower.\nDefend your tower, don't let your opponent conquer it!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/dice-duel/id1558821430",
	videoLink: "",
},
{
	title: "Rope Pull",
	iconUrl: "rope_pull/icon.png",
	imageUrl: "rope_pull/1.png",
	imageUrlTwo: "rope_pull/2.png",
	gifUrl: "rope_pull/rope_pull.gif",
	description: "Rope Pull is a hyper casual game where you place your players to defeat opponent.\nPull them in to the river!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/rope-pull/id1557489894",
	videoLink: "",
},
{
	title: "Lumberjack.io",
	iconUrl: "lumberjack/icon.png",
	imageUrl: "lumberjack/1.png",
	imageUrlTwo: "lumberjack/2.png",
	gifUrl: "lumberjack/lumberjack.gif",
	description: "Lumberjack.io is a hyper casual game where you have to chop the trees and collect axes.\n Survive this battle and become the strongest lumberjack!\n Exciting, easy to play, bright and positive design!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/lumberjack-io/id1555251470",
	videoLink: "",
},
{
	title: "Matches - Chain Reaction Game",
	iconUrl: "matches/icon.png",
	imageUrl: "matches/1.png",
	imageUrlTwo: "matches/2.png",
	gifUrl: "matches/matches.gif",
	description: "Matches is a hyper casual chain reaction puzzle game.\n Place matches in the correct positions to make burning chain reaction.\n Exciting, easy to play, fun to watch!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/matches-chain-reaction-game/id1554053669",
	videoLink: "",
},
{
	title: "Juicy Cut 3D",
	iconUrl: "juicyCut3d/icon.png",
	imageUrl: "juicyCut3d/1.png",
	imageUrlTwo: "juicyCut3d/2.png",
	gifUrl: "juicyCut3d/juicyCut.gif",
	description: "Juicy Cut 3D is a hyper casual single-tap pinball game.\n Flip fruits in the blender for a juicy storm!\n Exciting, easy to play, bright and positive design!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/juicy-cut-3d/id1550925275",
	videoLink: "",
},
{
	title: "Fun Delivery",
	iconUrl: "funDelivery/icon.png",
	imageUrl: "funDelivery/1.png",
	imageUrlTwo: "funDelivery/2.png",
	gifUrl: "funDelivery/funDelivery.gif",
	description: "Fun Delivery is a hyper casual game where you have to deliver packages.\n Collect fuel for jetpack and set new hight records!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/fun-delivery/id1538439666",
	videoLink: "",
},
{
	title: "Bullet Run!",
	iconUrl: "bulletRun/icon.png",
	imageUrl: "bulletRun/1.png",
	imageUrlTwo: "bulletRun/2.png",
	gifUrl: "bulletRun/bulletRun.gif",
	description: "Bullet Run! is a hyper casual game where you shoot targets to move forward.\n Relaxing shooter runner experiance!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/bullet-run/id1535449168",
	videoLink: "",
},
{
	title: "Color Down 3D",
	iconUrl: "colorDown/icon.png",
	imageUrl: "colorDown/1.png",
	imageUrlTwo: "colorDown/2.png",
	gifUrl: "colorDown/Color_Down.gif",
	description: "Color Down 3D is a hyper casual game where you have to turn the tower to match the colors.\n Exciting, easy to play, bright and positive design!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/color-down-3d/id1515554174",
	videoLink: "",
},
{
	title: "Ring Buzz",
	iconUrl: "ringBuzz/icon.png",
	imageUrl: "ringBuzz/1.png",
	imageUrlTwo: "ringBuzz/2.png",
	gifUrl: "ringBuzz/Ring_Buzz.gif",
	description: "Ring Buzz is a hyper casual game where you have to tap on the screen to make the ring jump.\n Avoid hitting the line to complete the levels!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/ring-buzz/id1524626260",
	videoLink: "",
},
{
	title: "Crush Color 3D",
	iconUrl: "crushColor/icon.png",
	imageUrl: "crushColor/1.png",
	imageUrlTwo: "crushColor/2.png",
	gifUrl: "crushColor/Crush_Color.gif",
	description: "Crush Color 3D is a hyper casual game where you have to crush all blocks in the maze.\n Enjoy colorful levels and relax your mind!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/crush-color-3d/id1396999548",
	videoLink: "",
},
{
	title: "2048 Stack 3D",
	iconUrl: "2048Stack/icon.png",
	imageUrl: "2048Stack/1.png",
	imageUrlTwo: "2048Stack/2.png",
	gifUrl: "2048Stack/2048_Stack.gif",
	description: "2048 Stack 3D is fun & relaxing puzzle game where you have to join the tiles and build 2048 stack.\n Good game for brain sharpening, perfect time killer and anti-stress entertainment!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/2048-stack-3d/id1490306827",
	videoLink: "",
},
{
	title: "Ball Flow",
	iconUrl: "ballFlow/icon.png",
	imageUrl: "ballFlow/1.jpg",
	imageUrlTwo: "ballFlow/2.jpg",
	gifUrl: "ballFlow/ball_flow.gif",
	description: "Ball Flow is a hyper casual game where you have to drop balls in the same color tube.\n Good game for brain sharpening, perfect time killer and anti-stress entertainment!",
	playStoreLink: "https://play.google.com/store/apps/details?id=io.guruu.games.ball.flow",
	appStoreLink: "https://apps.apple.com/us/app/ball-flow/id1516223285",
	videoLink: "",
},
{
	title: "Safari Run",
	iconUrl: "safariRun/icon.png",
	imageUrl: "safariRun/1.jpg",
	imageUrlTwo: "safariRun/2.jpg",
	gifUrl: "safariRun/safari_run.gif",
	description: "Safari Run is a hyper casual game.\n Collect animals in the run and bring them to the finish line!",
	playStoreLink: "",
	appStoreLink: "https://apps.apple.com/us/app/car-magnet-3d/id1475775973",
	videoLink: "",
},
{
	title: "Fire Line",
	iconUrl: "fireLine/fireLine_icon.png",
	imageUrl: "fireLine/fireLine.jpg",
	imageUrlTwo: "fireLine/fireLine2.jpg",
	gifUrl: "fireLine/fireLine.gif",
	description: "Fire Line is a single tap hyper casual game.\n Beat levels and collect stars!",
	playStoreLink: "",
	appStoreLink: "https://itunes.apple.com/us/app/fire-line/id1350740545?ls=1&mt=8",
	videoLink: "",
},
{
	title: "Cut Half",
	iconUrl: "halfCut/halfCut_icon.png",
	imageUrl: "halfCut/halfCut.jpg",
	imageUrlTwo: "halfCut/halfCut2.jpg",
	gifUrl: "halfCut/halfCut.gif",
	description: "Cut unique shapes in 2, 3 or 4 even pieces!\n Can you get all stars in each level?",
	playStoreLink: "",
	appStoreLink: "https://itunes.apple.com/us/app/cut-half/id1453325376?ls=1&mt=8",
	videoLink: "",
},
{
	title: "Color vs White",
	iconUrl: "colorVsWhite/color_icon.png",
	imageUrl: "colorVsWhite/color.png",
	imageUrlTwo: "colorVsWhite/color2.png",
	gifUrl: "colorVsWhite/color.gif",
	description: "Lead baloon up in the air as high as possible\n You can touch colours but don’t touch the white!",
	playStoreLink: "https://play.google.com/store/apps/details?id=io.guruu.games.color.vs.white",
	appStoreLink: "https://itunes.apple.com/lv/app/color-vs-white/id1437621567?mt=8",
	videoLink: "",
},
{
	title: "Dunk Low!",
	iconUrl: "dunkLow/dunk_low_icon.png",
	imageUrl: "dunkLow/dunk_low.png",
	imageUrlTwo: "dunkLow/dunk_low2.png",
	gifUrl: "dunkLow/dunk_low.gif",
	description: "Break blocks and dunk into the hoop to score points\n Upgrade ball power and speed to reach new levels!",
	playStoreLink: "https://play.google.com/store/apps/details?id=io.guruu.games.dunk.down",
	appStoreLink: "https://itunes.apple.com/us/app/basketball-dunk-ball-vs-block/id1438391571?ls=1&mt=8",
	videoLink: "",
},
{
	title: "Draw Clean",
	iconUrl: "drawClean/draw_icon.png",
	imageUrl: "drawClean/draw_clean.png",
	imageUrlTwo: "drawClean/draw_clean2.png",
	gifUrl: "drawClean/draw_clean.gif",
	description: "Super challenging drawing game for everyone\n Erease the blue line and go wild by getting 3 crowns in all levels!",
	playStoreLink: "https://play.google.com/store/apps/details?id=io.guruu.games.draw.n.delete",
	appStoreLink: "https://itunes.apple.com/lv/app/draw-clean/id1457950238?mt=8",
	videoLink: "",
},
{
	title: "Egg Farm",
	iconUrl: "eggFarm/egg_farm_icon.png",
	imageUrl: "eggFarm/egg_farm.png",
	imageUrlTwo: "eggFarm/egg_farm2.png",
	gifUrl: "eggFarm/egg_farm.gif",
	description: "Sell eggs and upgrade your egg farm\n Invest money in marketing and quality to rise the price\n Buy bigger egg container and upgrade hens to lay more eggs\n Fun farming strategy game!",
	playStoreLink: "https://play.google.com/store/apps/details?id=io.guruu.egg.farm",
	appStoreLink: "https://itunes.apple.com/lv/app/egg-farm/id1457948773?mt=8",
	videoLink: "",
},
{
	title: "Number Blocks!",
	iconUrl: "numberBlocks/numberBlocks_icon.png",
	imageUrl: "numberBlocks/numberBlocks.png",
	imageUrlTwo: "numberBlocks/numberBlocks2.png",
	gifUrl: "numberBlocks/numberBlocks.gif",
	description: "Tap on the screen to guide a ball and hit blocks in ascending order\n Hit Bonus Stars on blocks to earn Coins\n Unlock new balls by score Challenge!\n Try to hit as many blocks as possible!",
	playStoreLink: "https://play.google.com/store/apps/details?id=io.guruu.games.number.blocks",
	appStoreLink: "https://itunes.apple.com/lv/app/number-blocks/id1371455236?mt=8",
	videoLink: "",
}
];
