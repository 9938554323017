import * as React from "react";

import { Game } from "./Game";
import { GameInfo, gamesList } from "./gamesList";

import "./Games.css";

export class Games extends React.Component {
    render() {
        return (
            <div>
                {gamesList.map(this.renderGame)}
            </div>
        );
    };

    private renderGame = (gameInfo: GameInfo, i: number) => <Game info={gameInfo} index={i} key={i} />;
};
