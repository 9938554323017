import * as React from 'react';

// import { LogoSvg } from "../header/logo";

import "./Footer.css";

export class Footer extends React.Component {
    render() {
        return (
            <div className="footerContainer">
                <div className="footerLogo">
                    {/* <LogoSvg /> */}
                </div>
                
                © GURUU. ALL RIGHTS RESERVED.
            </div>
        );
    };
};
