import * as React from 'react';

import "./Header.css";

export class Header extends React.Component {
    render() {
        return (
            <div className="headerContainer">
                <img alt="logo" src={require("./assets/logo.svg")} />
            </div>
        );
    };
};
